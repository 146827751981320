import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { SingUpService } from '../shared/services/signup.service';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private signup: SingUpService) {}

    canActivate(route: ActivatedRouteSnapshot) {

        const currentUser = this.authService.currentUserValue;
        // if (currentUser) {
        //     return true;
        // } else {
        //     this.router.navigate(['/home']);
        //     return false;
        // }
        if (currentUser.userType) {
            if(route.data && route.data.allowedRoles){
                if(route.data.allowedRoles.includes(currentUser.userType)){
                    return true;
                }
                else{
                    if(currentUser.userType === (2 || 3)){
                        this.router.navigate(['dashboard/seller/dashboard']);
                        return true;
                    }
                    if(currentUser.userType === (4 || 5)){
                        this.router.navigate(['dashboard/buyer/dashboard']);
                        return true;
                    }
                }
            }
            return true;
        }else{   
            this.router.navigate(['/home']);
            return false;
        }
    }
}