import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIHeaderService } from '../services/ui-header.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SingUpService } from '../services/signup.service';
import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../services1/shared.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {

  isScrolled: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 0;
  }

  checkURL: boolean;
  currentUser:any;
  shortName:string;
  @Input() ClassGet;
  hideHeader:boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private signUpService:SingUpService,
    private cd: ChangeDetectorRef,
    private sharedService: SharedService
  ) {

  }

  ngOnInit(): void {

    setTimeout(() => {
      this.hideHeader = true;
    },2000)
    let chech = window.setInterval(()=>{
    //   if(this.router.url=='/home'){
    //     (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
    //   }else{
    //     (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-dark-bg');
    //   }

    if(this.router.url!='/vehicle' && this.router.url!='/home'){
      localStorage.removeItem('search');
    }

      let detail = this.router.url.includes('/contact-us/details')
      if(this.router.url=='/home'){
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-white-bg');
      }
      else if(this.router.url=='/contact-us' || this.router.url=='/sell' || this.router.url=='/buy' || detail==true){
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-white-bg');
      }
      else{
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-white-bg');
      }
    },100)
  
    this.currentUser = this.authService?.currentUserValue;
    this.shortName = this.currentUser?.name?.slice(0,2);
    // console.log("class get from add vehicle",this.ClassGet);


  }

  HeaderStyle1() {
    this.hideHeader = false;
    setTimeout(() => {
      this.hideHeader = true;
    },3000)
    this.sharedService.hideFooterElement(true);
    this.checkURL = true;
  }
  HeaderStyle2() {
    // this.sharedService.hideFooterElement(true);
    this.checkURL = false;
  }

  logout(){
    this.hideHeader = false;
    setTimeout(() => {
      this.hideHeader = true;
    },3000)
    this.sharedService.hideFooterElement(true);
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/home');
    this.currentUser = null;
    //location.reload();
    this.cd.detectChanges();
  }

  activateSignUpPopUp(isEnable){
    this.signUpService.EnableSignUpModel(isEnable)
  }
  
}
