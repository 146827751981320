import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';

@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    private enableParticlesSubject = new BehaviorSubject<boolean>(false);
    enableParticles$ = this.enableParticlesSubject.asObservable();
    
    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    login(user: User) {
        return this.http.post<any>(this.url + 'user/login', user);
    }

    reset(email: any) {
        return this.http.patch<any>(this.url + 'user/reset-password', email);
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('lang');
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.patch(this.url + 'user/change-password', data);
    }

    showParticles() {
        const particlesContainer = document.getElementById('particles-js');
    if (particlesContainer) {
      particlesContainer.style.display = 'block';
    }
        //this.enableParticlesSubject.next(true);
    }
    
    hideParticles() {
       // this.enableParticlesSubject.next(false);
       const particlesContainer = document.getElementById('particles-js');
    if (particlesContainer) {
      particlesContainer.style.display = 'none';
    }
    }
}