import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedRoutingModule } from './shared-routing.module';

// ngx bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LayoutComponent } from './layout1/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { FooterComponent } from './footer/footer.component';
import { TestimonialsComponent } from './layout1/testimonials/testimonials.component';
import { MobileAppicationComponent } from './layout1/mobile-appication/mobile-appication.component';
import { LandingPageComponent } from '../main/landing-page.component';
import { SellerSidebarComponent } from '../main/dashboard/seller/sidbar/sidbar.component';
import { BuyerSidebarComponent } from '../main/dashboard/buyer/sidebar/sidebar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SellerSidebarComponent,
    BuyerSidebarComponent,
    SidebarComponent,
    TestimonialsComponent,
    SafeHtmlPipe,
    LocalDatePipe,
    WarningDialogComponent
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    SharedRoutingModule, 
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    MatExpansionModule,
    ReactiveFormsModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    SidebarComponent,
    SellerSidebarComponent,
    BuyerSidebarComponent,
    HeaderComponent,
    FooterComponent,
    TestimonialsComponent,
    SafeHtmlPipe,
    LocalDatePipe
  ],
})
export class SharedModule { }
