import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { MainComponent } from './auth/components/main/main.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { LandingPageComponent } from './main/landing-page.component';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';
import { BuyerDashboardComponent } from './main/dashboard/buyer/buyer-dashboard.component';
import { SellerDashboardComponent } from './main/dashboard/seller/seller-dashboard.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { YoutubeBannerComponent } from './main/youtube-banner/youtube-banner.component';


const routes: Routes = [

  {
    path:'vehicle/details',
    component: VehicleDetailsComponent
  },
  {
    path:'banner',
    component: YoutubeBannerComponent
  },
  {
    
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./main/landing-page.module').then(m => m.LandingPageModule)
      },
    ]
  },
  // { path: '**', redirectTo: '', pathMatch: 'full' },

  {
    path: 'dashboard/buyer',
    component: BuyerDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/main/dashboard/buyer/buyer-dashboard.module').then(m => m.BuyerDashboardModule)
      },
    ]
  },
  {
    path: 'dashboard/seller',
    component: SellerDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/main/dashboard/seller/seller-dashboard.module').then(m => m.SellerDashboardModule)
      },
    ]
  },
  {
    path: 'vehicle',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },

  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  // },
  // {
  //   path: '',
  //   component: ViewsComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: 'edit-profile', component: EditProfileComponent },
  //     {
  //       path: 'dashboard',
  //       loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
  //     },
  //     {
  //       path: 'users',
  //       loadChildren: () =>
  //         import('./views/users/users.module').then((m) => m.UsersModule),

  //     },

  //     { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  //     { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
  //   ]
  // },
  // { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // { path: '**', redirectTo: '', pathMatch: 'full' },
   { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
