import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class ConfirmdialogServiceService {

  bsModalRef: BsModalRef;
  private userChoiceSubject = new Subject<boolean>();

  constructor(private modalService: BsModalService) { }

  showConfirmationDialog(message: string): Observable<boolean> {
    const initialState = {
      message: message,
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });

    return this.userChoiceSubject.asObservable();
  }

  setUserChoice(choice: boolean): void {
    this.userChoiceSubject.next(choice);
  }

  
  showWarningDialog(message: string): Observable<boolean> {
    const initialState = {
      message: message,
    };
    this.bsModalRef = this.modalService.show(WarningDialogComponent, { initialState});

    return this.userChoiceSubject.asObservable();
  }

  hideWarningDialog(){
    this.modalService.hide();
  }
}
