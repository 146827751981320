import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmdialogServiceService } from './confirmdialog-service.service';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(
    public bsModalRef: BsModalRef, 
    private confirmationService: ConfirmdialogServiceService) { }

  ngOnInit() {
  }

  confirm(): void {
    // Handle your confirm logic here
    this.confirmationService.setUserChoice(true);
    this.bsModalRef.hide();
  }

  cancel(): void {
    // Handle your cancel logic here
    this.confirmationService.setUserChoice(false);
    this.bsModalRef.hide();
  }
}
