<div class="side-navbar">
    <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/dashboard/seller/dashboard">
                <span class="material-symbols-outlined"> home </span>
                Dashboard
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/seller/edit-profile"  [queryParams]="{edit:'1'}">
                <span class="material-symbols-outlined"> manage_accounts </span>
                <span class="label-menu">Profile </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/seller/vehicle-list">
                <span class="material-symbols-outlined"> directions_car </span>
                My Vehicles
            </a>
        </li>
        <!-- <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/seller/sold-vehicles">
                <span class="material-symbols-outlined"> sell </span>
                Sold Vehicles
            </a>
        </li> -->
        <li>
            <a class="nav-link"  routerLinkActive="active" routerLink="/dashboard/seller/add-vehicle">
                <span class="material-symbols-outlined"> add_box </span>
                Add Vehicles
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/seller/sales"  [queryParams]="{edit:'1'}">
                <span class="material-symbols-outlined"> sell </span>
                <span class="label-menu">Sales </span>
            </a>
        </li>
        <li>
            <a class="nav-link"  routerLinkActive="active" routerLink="/dashboard/seller/transaction">
                <span class="material-symbols-outlined">
                    payments
                    </span>
                Payment
            </a>
        </li>

        <li>
            <a class="nav-link"  routerLinkActive="active" routerLink="/dashboard/seller/statement">
                <span class="material-symbols-outlined">
                    receipt_long
                    </span>
                Statement
            </a>
        </li>

        <!-- <li>
            <a class="nav-link"  routerLinkActive="active" routerLink="/dashboard/seller/order-history">
                <span class="material-symbols-outlined"> history </span>
                Order History
            </a>
        </li> -->
    </ul>
</div>