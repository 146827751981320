<app-header class="hader-dark-bg-- header-fixed"></app-header>

<div class="my-account-home clearfix">
    <div class="sidebar-main-wpr" [@widthGrow]="state" style="height: '{getRightDivHeight()}';overflow: auto;">
        <div class="menu-overlay" *ngIf="menuOverlay"></div>
        <app-buyer-sidebar></app-buyer-sidebar>
     </div>
     <div class="main-dashboard-panel my-account-panel">
        <div class="container-fluid">
            <div class="row">
                <div class="router-wpr col-md-12">
                    <div class="dashboard-menu" (click)="changeState()">
                        <span class="material-symbols-outlined">
                            segment
                        </span>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>