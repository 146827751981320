<header class="fixed-menu site-header hader-style-1 hader-white-bg" [ngClass]="{'scrolled': isScrolled}" id="commonHeader" *ngIf="hideHeader">  <!-- [ngClass]="{'hader-dark-bg' : checkURL == false}" -->
  <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="header-box">
                  <button type="button" onclick="openNav()" class="navbar-toggle collapsed menu-collapsed-button" data-toggle="collapse" data-target="#navbar-primary-collapse" aria-expanded="false">
                      <span class="material-symbols-outlined">
                        menu
                        </span>
                    </button>
                    <div class="site-branding">
                        <a routerLink="/home" (click)="HeaderStyle1()" title="ACA">
                            <img src="assets/img/logo.png" class="logo-black" alt="Logo">   
                            <img src="assets/img/logo-white.png" class="logo-white" alt="Logo">
                        </a>
                    </div>
                    <div class="header-menu hidden-xs collapse navbar-collapse navbar-right ts-mnus header-right-menu" id="navbar-primary-collapse">
                        <nav class="main-navigation">
                          <ul class="menu">
                            <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                    routerLink="/vehicle">Vehicles</a></li>
                            <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                    routerLink="/auction">Auctions</a></li>
                            <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                    routerLink="/buy">Buyers</a></li>
                            <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                    routerLink="/sell">Sellers</a></li>
                            <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                    routerLink="/contact-us">Contact Us</a></li>
                            <!-- <li><a routerLink="/sign-up/complete-profile" (click)="HeaderStyle2()">Other</a>
                            </li> -->
                        </ul>
                            <!-- <ul class="menu">
                                <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                        routerLink="/vehicle">Vehicles</a></li>
                                <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                        routerLink="/auction">Auctions</a></li>
                                <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                        routerLink="/buy">Buyers</a></li>
                                <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                        routerLink="/sell">Sellers</a></li>
                                <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                        routerLink="/contact-us">Contact Us</a></li>
                                <li><a routerLink="/sign-up/complete-profile" (click)="HeaderStyle2()">Other</a>
                                </li>
                            </ul> -->
                        </nav>
                        <div class="black-shadow"></div>                       
                    </div>
                     <!-- Mobile Menu -->

                     <div class="mnus-s hidden-sm hidden-md hidden-lg">
                      <div id="mySidenav" class="sidenav">
                         <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                         <ul>
                          <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                  routerLink="/vehicle">Vehicles</a></li>
                          <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                  routerLink="/auction">Auctions</a></li>
                          <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                  routerLink="/buy">Buyers</a></li>
                          <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                  routerLink="/sell">Sellers</a></li>
                          <li routerLinkActive="active" (click)="HeaderStyle2()"><a
                                  routerLink="/contact-us">Contact Us</a></li>
                          <!-- <li><a routerLink="/sign-up/complete-profile" (click)="HeaderStyle2()">Other</a>
                          </li> -->
                      </ul>
                      </div>
                    </div>

                    <!-- End Mobile Menu -->
                    <div class="header-menu-right">
                        <div class="button-box" *ngIf="!currentUser">
                            <!-- <a data-toggle="modal" data-target="#staticBackdrop" href="#" title="Log In"><span>Log
                                    In</span></a> -->
                            <a data-toggle="modal" data-target="#logInPopup" href="" (click)="activateSignUpPopUp(false)"
                                class="secondary-btn secondary-btn__light"><span>Sign
                                    In</span></a>
                            <a href="" class="secondary-btn" data-toggle="modal" data-target="#logInPopup" (click)="activateSignUpPopUp(true)"><span>Sign
                                    Up</span></a>
                        </div>
                        <div class="header-menu-dropdown" *ngIf="currentUser">
                            <div class="btn-group">
                                <div type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <div class="profile-pic">
                                    <div class="profile-name">
                                        {{shortName}}
                                    </div>
                                  </div>
                                  <div class="user-name">
                                    {{currentUser?.name}}
                                  </div>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <h4 class="user-name-mobile">Hi, {{currentUser?.name}}</h4>
                                  <div class="availableCredit">
                                   <h4> Available Credit Limit</h4>
                                    <h1>AED <span>{{currentUser?.creditLimit}}</span> </h1>
                                  </div>
                                  <button class="dropdown-item" type="button" [routerLink]="currentUser.userType === 4 || currentUser.userType === 5 ? '/dashboard/buyer/dashboard' : '/dashboard/seller/dashboard'" [queryParams]="{edit:'1'}">
                                    <i class="fa-regular fa-user"></i> User Dashboard
                                  </button>
                                  <button class="dropdown-item" type="button" [routerLink]="currentUser.userType === 4 || currentUser.userType === 5 ? '/dashboard/buyer/edit-profile' : '/dashboard/seller/edit-profile'" [queryParams]="{edit:'1'}">
                                    <i class="fa-regular fa-user"></i> Edit Profile
                                  </button>
                                  <button class="dropdown-item" type="button" [routerLink]="currentUser.userType === 4 || currentUser.userType === 5 ? '/dashboard/buyer/edit-profile' : '/dashboard/seller/edit-profile'" [queryParams]="{pass:'2'}">
                                    <i class="fa-solid fa-key"></i>  Change Password
                                  </button>
                                  <button class="dropdown-item" type="button" [routerLink]="currentUser.userType === 4 || currentUser.userType === 5 ? '/dashboard/buyer/edit-profile' : '/dashboard/seller/edit-profile'" [queryParams]="{plan:'1'}">
                                    <i class="fa-regular fa-file-lines"></i> Our Plan
                                  </button>
                                  <button class="dropdown-item" type="button" (click)="logout()">
                                    <i class="fa-solid fa-arrow-right-from-bracket"></i>  Logout
                                  </button>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header -->
<!-- <div class="hidden-xs collapse navbar-collapse navbar-right ts-mnus header-right-menu" id="navbar-primary-collapse">
  <ul class="nav navbar-nav ">
     <li class="page-scroll"><a class="header" href="#">Home</a></li>
     <li><a href="#">About Us</a></li>
        <li><a href="#">Products</a></li>
        <li><a href="#">Dermx Diary</a></li>
        <li><a href="#">Blogs </a></li>
        <li><a href="#">Careers</a></li>
        <li><a href="#">Contact US</a></li>
  </ul>
</div> -->
<!-- <div class="mnus-s hidden-sm hidden-md hidden-lg">
  <div id="mySidenav" class="sidenav">
     <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
     <ul>
        <li class="page-scroll"><a class="header" href="#">Home</a></li>
        <li><a href="#">About Us</a></li>
        <li><a href="#">Products</a></li>
        <li><a href="#">Dermx Diary</a></li>
        <li><a href="#">Blogs </a></li>
        <li><a href="#">Careers</a></li>
        <li><a href="#">Contact US</a></li>
     </ul>
  </div>
  <button type="button" onclick="openNav()" class="navbar-toggle collapsed menu-collapsed-button" data-toggle="collapse" data-target="#navbar-primary-collapse" aria-expanded="false">
  <span class="sr-only">Toggle navigation</span>
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
  <span class="icon-bar"></span>
  </button>
</div> -->
<!-- End header -->