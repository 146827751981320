<!-- Log In Modal -->
<div class="authPanelPopup auth modal fade" id="logInPopup" tabindex="-1" role="dialog" aria-labelledby="logInPopup"
    aria-hidden="true" [ngClass]="{'termCondition':termAndCondition}">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="header-stickey" *ngIf="!termAndCondition">
                    <img src="assets/img/icons/auth-matrix-2.svg" />
                </div>
                <button type="button" id="btnClose" (click)="closePopUp()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="left-section">

                                <!--Sign Up popup Section-->
                                <form class="form" *ngIf="SignUpPopupSection" [formGroup]="userForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Sign Up</h6>
                                        <h4>Sign Up For Free</h4>
                                    </div>
                                    <div class="input-body">
                                        <div class="form-group">
                                            <label class="form-label">Become a</label>
                                            <div class="tab-btn-section">
                                                <a href="JavaScript:void(0)" (click)="buyerTabActive()"
                                                    [ngClass]="{'active' : buyerTab == true}"
                                                    class="tab-btn"><span>Buyer</span></a>
                                                <a href="JavaScript:void(0)" (click)="sellerTabActive()"
                                                    [ngClass]="{'active' : sellerTab == true}"
                                                    class="tab-btn"><span>Seller</span></a>
                                            </div>
                                        </div>



                                        <div class="form-group">
                                            <label class="form-label" for="EmailorPhoneNumber">Phone Number</label>
                                            <ngx-intl-tel-input
                                            [cssClass]="'custom'"
                                            [preferredCountries]="preferredCountries"
                                            [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [searchCountryField]="[
                                              SearchCountryField.Iso2,
                                              SearchCountryField.Name
                                            ]"
                                            [selectFirstCountry]="false"
                                            [selectedCountryISO]="CountryISO.UnitedArabEmirates"
                                            [maxLength]="15"
                                            [phoneValidation]="true"
                                            [separateDialCode]="separateDialCode"
                                            [numberFormat]="PhoneNumberFormat.National"
                                            name="phone"
                                            formControlName="phone"
                                          >
                                          </ngx-intl-tel-input>
                                        </div>
                                        
                                        <!-- Email input -->
                                        <div class="form-group">
                                            <label class="form-label" for="EmailorPhoneNumber">Email</label>
                                            <input type="email" id="EmailorPhoneNumber" formControlName="email"
                                                placeholder="Enter Email Address or Phone Number"
                                                class="form-control" />
                                        </div>

                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Password</label>
                                                <div>
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Enter Password">
                                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                        </button>
                                                    </mat-form-field>
                                                </div>

                                            <!-- <input type="password" id="Password" placeholder="Enter Password"
                                                class="form-control" formControlName="password" /> -->
                                                <div *ngIf="f.password.errors" class="text-danger">
                                                    <span *ngIf="f.password.errors.pattern">
                                                        Password must contain at least one Uppercase, Lowercase, Digit and One Special symbol
                                                    </span>
                                                </div>
                                        </div>

                                        <!-- Retype password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Retype Password</label>
                                            <div>
                                                <mat-form-field appearance="fill">
                                                    <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="confirmPassword" placeholder="Enter Retype Password">
                                                    <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                                      <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <!-- <input type="password" id="Password" placeholder="Enter Retype Password"
                                                class="form-control" formControlName="confirmPassword" /> -->

                                        </div>

                                        <!-- Term Checkbox -->
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="form2Example31"
                                                checked formControlName="term" />
                                            <label class="form-check-label" for="form2Example31"> By clicking this box,
                                                I agree that I am at least 18 years of age and that I have read and
                                                agreed to the Al-Qaryah's
                                                Member<a href="#!"> Terms and Conditions</a>
                                            </label>
                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <a href="JavaScript:void(0)" id="btnSignUp" class="btn-block primary-btn"  [ngClass]="{'disabled': !isFormValid()}"
                                                (click)="OTPVerifyPopupActive()"><span>Sign
                                                    Up</span></a>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Not a member? <a href="JavaScript:void(0)"
                                                    (click)="LogInPopupActive()">Sign In</a></p>
                                        </div>
                                    </div>
                                </form>
                                <!--Sign Up popup Section-->

                                <!--Log In popup Section-->
                                <form *ngIf="LogInPopupSection" class="loginPopup form" [formGroup]="loginForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Login</h6>
                                        <h4>Welcome Back</h4>
                                    </div>
                                    <div class="input-body">
                                        <!-- Email input -->
                                        <div class="form-group">
                                            <label class="form-label" for="EmailorPhoneNumber">Email or Phone
                                                Number</label>
                                                <!-- <div style="position: absolute;" *ngIf="onlyNumbers==true ">
                                                    <span style="position: relative;left: 12px;top: 16px;">+91</span>
                                                </div> -->
                                                <input type="email" id="EmailorPhoneNumber"
                                                    placeholder="Enter Email Address or Phone Number"
                                                    class="form-control" formControlName="email" [ngClass]="{'leftPadding':onlyNumbers==true}"  />
                                        </div>

                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Password</label>
                                            <div>
                                                <mat-form-field appearance="fill">
                                                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="password" placeholder="Enter Password">
                                                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                                      <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                            <!-- <input type="password" id="Password" placeholder="Enter Password"
                                                class="form-control" formControlName="password" /> -->

                                        </div>

                                        <div class="f-pass form-group">
                                            <a href="javascript:void()" (click)="ResetPasswordPopupActive()">Forgot
                                                password?</a>
                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <button class="btn-block primary-btn" (click)="signIn()"><span>Sign In</span></button>
                                            <!-- <a ></a> -->
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Not a member? <a href="javascript:void(0)"
                                                    (click)="SignUpPopupActive()">Sign Up</a></p>
                                        </div>
                                    </div>
                                </form>
                                <!--End Log In popup Section-->

                                <!--Reset Password popup Section-->
                                <div class="form" *ngIf="ResetPasswordPopupSection">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Forgot Password</h6>
                                        <h4>Reset Your Password</h4>
                                    </div>
                                    <div class="input-body">
                                        <!-- Email input -->
                                        <div>
                                            <label class="form-label" for="Password">Email or Phone Number</label>
                                            <input type="email" id="Password" [(ngModel)]="resetEmilOrPhone" placeholder="Email or Phone Number"
                                                class="form-control" />

                                        </div>
                                        <div *ngIf="userNotFound">
                                            <label class="form-label" style="color: red;">User not found.</label>
                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <a href="JavaScript:void()" (click)="GetUserByEmail()"  [ngClass]="{'disabled': !resetEmilOrPhone}"
                                                class="btn-block primary-btn"><span>send Verification Code</span></a>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Back to <a href="JavaScript:void(0)" (click)="LogInPopupActive()">Log
                                                    In</a></p>
                                        </div>
                                    </div>
                                </div>
                                <!--Forgot Password popup Section-->

                                <!--OTP Verification popup Section-->

                                <div class="form" *ngIf="termAndCondition" style="padding-right: 22%;">
                                    <div>
                                        <h6>Agreement</h6>
                                    </div>
                                        <ol>
                                            <li>The buyer has 5 days from the date of purchase to clear full payment and to pick up the car.</li>
                                            <li>If the buyer fails to pick up the car within the given time, a storage fee of 50 AED/day will be applied.</li>
                                            <li>If the buyer fails to pay the remaining balance within 5 days from purchase date or fails to pick up the car within the given time from the purchase date after making the full payment, Al Qaryah Cars Auction L.L.C. reserve the rights to cancel the car.</li>
                                            <li>Sales cannot be reversed or exchanged and the paid amount cannot be refunded or adjusted with another car after purchase.</li>
                                            <li>Buyers are advised to carefully inspect the car before purchasing since the sale is on an "as-is" basis with no warranty.</li>
                                            <li>For "Export Security Deposit" the original documents i.e. Export Exit Certificate from relevant customs department of UAE and BL/Consignment Note should be submitted within 90 days from the invoice date. The Export date should fall within 90 days from the date of invoice.</li>
                                            <li>This invoice is raised by the seller (Principal Supplier) pursuant to article 59(11) of the Executive Regulation under UAE VAT Laws.</li>
                                            <li>This invoice is raised in pursuant to article 8.1 of VAT automotive industry.</li>
                                            <li>The buyer has 5 days from the date of purchase to clear full payment and to pick up the car.</li>
                                            <li>If the buyer fails to pick up the car within the given time, a storage fee of 50 AED/day will be applied.</li>
                                            <li>If the buyer fails to pay the remaining balance within 5 days from purchase date or fails to pick up the car within the given time from the purchase date after making the full payment, Al Qaryah Cars Auction L.L.C. reserve the rights to cancel the car.</li>
                                            <li>Sales cannot be reversed or exchanged and the paid amount cannot be refunded or adjusted with another car after purchase.</li>
                                            <li>Buyers are advised to carefully inspect the car before purchasing since the sale is on an "as-is" basis with no warranty.</li>
                                            <li>For "Export Security Deposit" the original documents i.e. Export Exit Certificate from relevant customs department of UAE and BL/Consignment Note should be submitted within 90 days from the invoice date. The Export date should fall within 90 days from the date of invoice.</li>
                                            <li>This invoice is raised by the seller (Principal Supplier) pursuant to article 59(11) of the Executive Regulation under UAE VAT Laws.</li>
                                            <li>This invoice is raised in pursuant to article 8.1 of VAT automotive industry.</li>

                                            <li>The buyer has 5 days from the date of purchase to clear full payment and to pick up the car.</li>
                                            <li>If the buyer fails to pick up the car within the given time, a storage fee of 50 AED/day will be applied.</li>
                                            <li>If the buyer fails to pay the remaining balance within 5 days from purchase date or fails to pick up the car within the given time from the purchase date after making the full payment, Al Qaryah Cars Auction L.L.C. reserve the rights to cancel the car.</li>
                                            <li>Sales cannot be reversed or exchanged and the paid amount cannot be refunded or adjusted with another car after purchase.</li>
                                            <li>Buyers are advised to carefully inspect the car before purchasing since the sale is on an "as-is" basis with no warranty.</li>
                                            <li>For "Export Security Deposit" the original documents i.e. Export Exit Certificate from relevant customs department of UAE and BL/Consignment Note should be submitted within 90 days from the invoice date. The Export date should fall within 90 days from the date of invoice.</li>
                                            <li>This invoice is raised by the seller (Principal Supplier) pursuant to article 59(11) of the Executive Regulation under UAE VAT Laws.</li>
                                            <li>This invoice is raised in pursuant to article 8.1 of VAT automotive industry.</li>

                                            <li>The buyer has 5 days from the date of purchase to clear full payment and to pick up the car.</li>
                                            <li>If the buyer fails to pick up the car within the given time, a storage fee of 50 AED/day will be applied.</li>
                                            <li>If the buyer fails to pay the remaining balance within 5 days from purchase date or fails to pick up the car within the given time from the purchase date after making the full payment, Al Qaryah Cars Auction L.L.C. reserve the rights to cancel the car.</li>
                                            <li>Sales cannot be reversed or exchanged and the paid amount cannot be refunded or adjusted with another car after purchase.</li>
                                            <li>Buyers are advised to carefully inspect the car before purchasing since the sale is on an "as-is" basis with no warranty.</li>
                                            <li>For "Export Security Deposit" the original documents i.e. Export Exit Certificate from relevant customs department of UAE and BL/Consignment Note should be submitted within 90 days from the invoice date. The Export date should fall within 90 days from the date of invoice.</li>
                                            <li>This invoice is raised by the seller (Principal Supplier) pursuant to article 59(11) of the Executive Regulation under UAE VAT Laws.</li>
                                            <li>This invoice is raised in pursuant to article 8.1 of VAT automotive industry.</li>
                                        </ol>

                                        <div>
                                            <a href="JavaScript:void(0)" class="btn-block primary-btn"
                                                (click)="acceptTermCondition()"><span>Accept</span></a>
                                        </div>


                                </div>

                                <form class="form" *ngIf="OTPVerifyPopupSection" [formGroup]="otpForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Verification</h6>
                                        <h4>Enter Verification Code</h4>
                                    </div>
                                    <div class="input-body">

                                        <div class="form-group">
                                            <label class="form-label" for="EmailorPhoneNumber">Please type the
                                                verification code sent to</label>
                                        </div>

                                        <!-- Email input -->
                                        <div class="form-group">
                                            <label class="form-label" for="EmailorPhoneNumber">
                                                <!-- +971 123 123 1234 -->
                                                 {{userForm?.value?.email}}</label>
                                            <div class="opt-section">
                                                <input type="text" maxlength="1" id="first" placeholder="-" formControlName="first" (keydown)="checkBackPress($event,1)"  (keyup)="moveToAnother($event,1)" class="form-control" />
                                                <input type="text" maxlength="1" id="second" placeholder="-" formControlName="second" (keydown)="checkBackPress($event,2)" (keyup)="moveToAnother($event,2)" class="form-control" />
                                                <input type="text" maxlength="1" id="third" placeholder="-" formControlName="third" (keydown)="checkBackPress($event,3)" (keyup)="moveToAnother($event,3)" class="form-control" />
                                                <input type="text" maxlength="1" id="forth" placeholder="-" formControlName="forth" (keydown)="checkBackPress($event,4)" (keyup)="moveToAnother($event,4)" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="opt-counter-section form-group">
                                            <span class="no-counter">00:30</span>
                                            <span>Have you received the code? <a href="javascript:void()">Resend</a></span>
                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <a href="JavaScript:void(0)" class="btn-block primary-btn" [ngClass]="{'disabled':isEnableVerifyBtn}"
                                                (click)="ForgotPasswordPopupActive()"><span>Verify</span></a>
                                        </div>

                                    </div>
                                </form>
                                <!-- End OTP Verification popup Section-->


                                <!--Forgot Password popup Section-->
                                <form class="form" [formGroup]="resetPasswordForm" *ngIf="ForgotPasswordPopupSection">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Change Password</h6>
                                        <h4>Change Your Password</h4>
                                    </div>
                                    <div class="input-body">


                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Enter New Password</label>
                                            <input type="password" formControlName="password" id="Password" placeholder="Enter New Password"
                                                class="form-control" />

                                        </div>

                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Retype New Password</label>
                                            <input type="password" formControlName="confirmPassword" id="Password" placeholder="Retype New Password"
                                                class="form-control" />

                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <a href="JavaScript:void(0)" (click)="UpdatePasswordFun()"
                                                class="btn-block primary-btn"  [ngClass]="{'disabled': !resetPasswordForm.valid || isPasswordMatchFun()}"><span>Update Password</span></a>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Back to <a href="#!">Log In</a></p>
                                        </div>
                                    </div>
                                </form>
                                <!--Forgot Password popup Section-->
                            </div>
                        </div>
                        <div class="col-md-6 popupCarSection ">
                            <div class="right-section"  *ngIf="!termAndCondition">
                                <div class="bg"></div>
                                <div class="stickey">
                                    <img src="assets/img/auth-matrix-1.png" />
                                </div>
                                <div class="car-img">
                                    <img class="image-responsive" src="assets/img/auth-car-1.png" />
                                </div>
                                <div class="blur-bg-section">
                                    <div class="blur-bg"></div>
                                    <h2>
                                        Unlock <span>unbeatable</span> deals on <span> top-quality </span> cars at our
                                        <span> online auction.</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="stickey-2"  *ngIf="!termAndCondition">
                                <img src="assets/img/icons/testimonial-matrix-right-1.svg" />
                            </div>
                            <div class="right-section"  *ngIf="termAndCondition">
                                <ol>
                                    <li>١. يملك المشتري ٥ أيام من تاريخ الشراء لدفع المبلغ الكامل واستلام السيارة</li>
                                    <li>٢. إذا فشل المشتري في استلام السيارة خلال هذه المدة، سيتم تطبيق رسوم تخزين قدرها ٥٠ درهم إماراتي / يوم</li>
                                    <li>٣. إذا فشل المشتري في دفع الرصيد المتبقي خلال ٥ أيام من تاريخ الشراء أو فشل في استلام السيارة خلال الوقت المحدد بعد دفع المبلغ الكامل، يحتفظ مزاد القرية للسيارات ش.ذ.م.م بالحق في إلغاء البيع</li>
                                    <li>٤. لا يمكن عكس المبيعات أو استبدالها ولا يمكن استرداد المبالغ المدفوعة أو تعديلها مع سيارة أخرى</li>
                                    <li>٥. يُنصح المشترين بفحص السيارة بعناية قبل الشراء نظرًا لأن البيع على أساس "كما هو " دون ضمان.</li>
                                    <li>٦. بالنسبة لـ "إيداع التصدير الأمني"، يجب تقديم المستندات الأصلية مثل شهادة خروج التصدير من قسم الجمارك ذو الصلة في الإمارات و سند بوليصة الشحن خلال ٩٠ يومًا من تاريخ الفاتورة. يجب أن يكون تاريخ التصدير ضمن ٩٠ يوما من تاريخ الفاتورة</li>
                                    <li>٧. يتم إصدار هذه الفاتورة من قبل البائع (المورد الرئيس) وفقًا للمادة (٥٩ (11) من اللائحة التنفيذية بموجب قوانين ضريبة القيمة المضافة في دولة الإمارات</li>
                                    <li>٨. يتم إصدار هذه الفاتورة من قبل البائع (المورد الرئيس) وفقًا للمادة (٥٩ (11) من اللائحة التنفيذية بموجب قوانين ضريبة القيمة المضافة في دولة الإمارات</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Log In Modal -->


<div class="modal fade" id="msgPopup" tabindex="-1" role="dialog" aria-labelledby="msgPopup" aria-hidden="true">
    <div class="modal-msg modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="icon-section">
                    <i class="fa fa-check"></i>
                </div>
                <h3>Vehicle added successfully!
                    you are able to see it when it 
                    approved from admin.</h3>
                <div class="button-section">
                <a href="" class="primary-btn primary-btn__d-block" data-dismiss="modal"><span>Done</span></a>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- Modal -->
<!-- <div class="modal fade" id="logInPopup2" tabindex="-1" role="dialog" aria-labelledby="logInPopup" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="logInPopupLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">.col-md-4</div>
                        <div class="col-md-6">.col-md-4 .ml-auto</div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div> -->
