import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-youtube-banner',
  templateUrl: './youtube-banner.component.html',
  styleUrls: ['./youtube-banner.component.scss']
})
export class YoutubeBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
