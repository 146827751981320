<section class="vehicle-details-wpr">
    <div class="filter-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="vehicle-details-heading">
                        <h2> <a href="#" routerLink="/vehicle">My Vehicle</a> <span>{{vehicleDetails?.make}}
                                {{vehicleDetails?.model}} {{vehicleDetails?.year}}</span> </h2>

                        <ul class="product-details">
                            <li>
                                <h5 class="product-title-info">Location: <span
                                        class="product-title-info-value">{{vehicleDetails?.branchs}}</span></h5>
                            </li>
                            <li>
                                <h5 class="product-title-info">Number of Cars: <span
                                        class="product-title-info-value">200</span></h5>
                            </li>
                        </ul>
                        <div class="v-d-btn-wpr" *ngIf="isAuctionVehicle">
                            <a *ngIf="isPreviousCarAvailable" class="secondary-btn secondary-btn_border-round secondary-btn__gray" (click)="GetAuctionVehicleByDisplay('p')"><span>Previous</span></a>
                            <a *ngIf="isNextCarAvailable" class="secondary-btn secondary-btn_border-round details-btn" (click)="GetAuctionVehicleByDisplay('n')"><span>Next</span></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div>
                    <div class="row p-slider">
                        <div class="col-md-3 slider-thumbnail">
                            <div class="" *ngFor="let i of vehicleDetails?.vinimages">
                                <img [src]="i?.image" alt="" (click)="onClickImage(i?.image)">
                            </div>
                            <!-- <ul>
                                    <li *ngFor="let i of vehicleDetails?.vinimages" style="margin: 20px;" [ngClass]="{'active':i?.image == showVehicle}">
                                        <img [src]="i?.image"  alt="" height="200" width="200" (click)="onClickImage(i?.image)" style="cursor: pointer;">
                                    </li>
                                </ul> -->
                        </div>
                        <div class="col-md-9 slider-details-wpr">
                            <div>
                                <div class="img-zoom-container" (mouseenter)="showResult = true; imageZoom('myimage', 'myresult');" (mouseleave)="showResult = false">
                                    <img id="myimage" [src]="showVehicle ? showVehicle : 'assets/img/landing-page/image21.png'" >
                                    <div id="myresult" class="img-zoom-result"></div>
                                    <div class="watch-list"
                                    *ngIf="currentUser?.userType === 4 || currentUser?.userType === 5">
                                    <i class="fa-solid fa-bookmark"></i><a (click)="addToWatchList()"
                                        style="margin-left: 5px;">Watch List</a>
                                </div>
                                </div>
                                  
                                <!-- <img [src]="showVehicle ? showVehicle : 'assets/img/landing-page/image21.png'" 
                                    style="height: 380px;" /> -->
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-details-wpr">
                    <ul class="product-discription">
                        <li>
                            <img src="assets/img/icons/Vector-1.png" />
                            <h6>{{vehicleDetails?.engines}} ltr</h6>
                        </li>
                        <li>
                            <img src="assets/img/icons/Vector-2.png" />
                            <h6>{{vehicleDetails?.milage ? vehicleDetails?.milage : 'NA'}} mi</h6>
                        </li>
                        <li>
                            <img src="assets/img/icons/Vector-3.png" />
                            <h6>{{vehicleDetails?.fueltypes ? vehicleDetails?.fueltypes : 'NA'}}</h6>
                        </li>
                        <li>
                            <img src="assets/img/icons/Vector-4.png" />
                            <h6>{{vehicleDetails?.drivetypes ? vehicleDetails?.drivetypes : 'NA'}}</h6>
                        </li>

                        <li>
                            <img src="assets/images/key.png" height="25" width="25"/>
                            <h6>{{vehicleDetails?.key ? vehicleDetails?.key : 'NA'}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="card mt-4">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" colspan="2">Vehicle Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Lot Number</td>
                                        <td class="text-right">{{vehicleDetails?.lotNo}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Vin Number </td>
                                        <td class="text-right">{{vehicleDetails?.vin}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Body Type</td>
                                        <td class="text-right">{{vehicleDetails?.model ? vehicleDetails?.model : 'NA'}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Engine Type</td>
                                        <td class="text-right">{{vehicleDetails?.engines ? vehicleDetails?.engines : 'NA'}} ltr</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Cylinders</td>
                                        <td class="text-right">4</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Drive</td>
                                        <td class="text-right">{{vehicleDetails?.drivetypes ? vehicleDetails?.drivetypes : 'NA'}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Fuel Type</td>
                                        <td class="text-right">{{vehicleDetails?.fueltypes ? vehicleDetails?.fueltypes : 'NA'}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Color Exterior</td>
                                        <td class="text-right">
                                            <div class="color-chip"
                                                [ngStyle]="{backgroundColor: vehicleDetails?.color}"></div>
                                        </td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Color Interior</td>
                                        <td class="text-right">
                                            <div class="color-chip"
                                                [ngStyle]="{backgroundColor: vehicleDetails?.interiorcolors}"></div>
                                        </td>
                                    </tr><!-- end tr -->


                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div class="vehicle-info">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card bid-info-card" *ngIf="vehicleDetails?.inventoryStatus==1 || vehicleDetails?.inventoryStatus==2">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" colspan="2">Bid Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Eligibility Status</td>
                                        <td class="text-right" *ngIf="currentUser">{{currentUser?.creditLimit >= vehicleDetails?.startingBid ? 'Eligible' : 'Not Eligible'}}</td>
                                        <td class="text-right" *ngIf="!currentUser">Not Eligible</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Starting Bid</td>
                                        <td class="text-right">AED <span class="text-danger">{{vehicleDetails?.startingBid ? vehicleDetails?.startingBid : 0}}</span></td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Bid Status</td>
                                        <td class="text-right">Pre Bid<span class="text-danger"></span></td>
                                    </tr><!-- end tr -->


                                    <tr>
                                        <td>Current Pre Bid</td>
                                        <td class="text-right">{{bidHistory?.bidDetails?.latestBidAmount ? bidHistory?.bidDetails?.latestBidAmount: 'Not Available'}}<span class="text-danger"></span></td>
                                    </tr><!-- end tr -->

                                    <tr>
                                        <td>Your Bid</td>
                                        <td class="text-right">{{bidHistory?.bidDetails?.userBidAmount ? bidHistory?.bidDetails?.userBidAmount : 'Not Available'}}<span class="text-danger"></span></td>
                                    </tr><!-- end tr -->


                                    <!-- <tr>
                                        <td>Bid Status</td>
                                        <td class="text-right">AED <span class="text-danger">3,40,000</span></td>
                                    </tr> -->
                                    <tr>
                                        <td colspan="2">
                                            <div class="bold">Make Pre Bid </div>
                                        </td>
                                    </tr><!-- end tr -->

                                    <tr>
                                        <td colspan="2">
                                            <div class="bid-info">
                                                <button class="btn btn-outline-secondary"
                                                    (click)="decreaseVal('custome')">-</button>
                                                <input type="text" id="pre-bid-val" class="form-control"
                                                    placeholder="Enter Amount" readonly [(ngModel)]="customBid"/>
                                                    <button class="btn btn-outline-secondary"
                                                    (click)="increaseVal('custome')">+</button>
                                                <a (click)="preBid()" 
                                                    class="primary-btn primary-btn_border-round details-btn primary-btn__d-block"
                                                    style="cursor:pointer;"><span>Pre Bid</span></a>
                                            </div>
                                        </td>
                                    </tr><!-- end tr -->

                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                        </div>
                    </div>
                </div>

                <div class="card mt-4">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" colspan="2"><span *ngIf="isAuctionVehicle">Auction </span>Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td class="text-right"><span *ngIf="isAuctionVehicle">{{auctionDetail?.title}}</span><span *ngIf="!isAuctionVehicle">N/A</span></td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Location</td>
                                        <td class="text-right">{{vehicleDetails?.branchs}}</td>
                                    </tr><!-- end tr -->
                                    <tr>
                                        <td>Sale Date</td>
                                        <td class="text-right"><span *ngIf="isAuctionVehicle">{{auctionDetail?.auctionDate | date}}</span><span *ngIf="!isAuctionVehicle">N/A</span></td>
                                    </tr><!-- end tr -->
                                    <!-- <tr>
                                        <td>Bid Status</td>
                                        <td class="text-right">Lorem Ipsum</td>
                                    </tr> -->
                                    <tr>
                                        <td>Time Left</td>
                                        <td class="text-right"><span class="text-danger"><span *ngIf="isAuctionVehicle">{{transform(auctionDetail?.auctionDate)}}</span><span *ngIf="!isAuctionVehicle">N/A</span></span></td>
                                    </tr><!-- end tr -->


                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="h3-heading">More Vehicles</h3>
                <div class="my-vehicle">
                    <div class="product-list-new product-card-view">
                        <!-- <div class="row"> -->
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let car of inventoryList">
                                <!-- <div class="my-account-panel-body">
                                      <div class="my-vehicle">
                                          <div class="product-list-new product-card-view ">
                                              <div class="items"> 
                                                  <div class="product-item product-item__style-2">
                                                      <div class="product-image">
                                                          <img [src]="car?.singleImages ? car?.singleImages: defaultImagCar " height="190"/>
                                                          <div class="product-image-dark-bg">
                                                              <div class="product-button">
                                                                  <a routerLink="/vehicle/details" [queryParams]="{id:car?._id}" class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>Details</span></a>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="product-header">
                                                          <h4 class="product-title">{{car?.make}} {{car?.model}} {{car?.year}}</h4>
                                                          <div class="product-main-info">
                                                              <h5 class="product-title-info">Stating Bid: <span
                                                                      class="product-title-info-value">{{car?.saleAmount}}</span>
                                                                  <span class="product-title-info-highlited">{{car?.reservePrice | currency:'INR'}}</span>
                                                              </h5>
                                                              <ul class="product-details">
                                                                  <li>
                                                                      <h5 class="product-title-info">Lot No: <span
                                                                              class="product-title-info-value">{{car?.lotNo}}</span></h5>
                                                                  </li>
                                                                  <li>
                                                                      <h5 class="product-title-info">Vin No: <span
                                                                              class="product-title-info-value">{{car?.vin}}</span></h5>
                                                                  </li>
                                                              </ul>
                                                          </div>
                                                          <ul class="product-discription">
                                                              <li>
                                                                  <img src="assets/img/icons/Vector-1.png" />
                                                                  <h6>{{car?.engines}} ltr</h6>
                                                              </li>
                                                              <li>
                                                                  <img src="assets/img/icons/Vector-2.png" />
                                                                  <h6>{{car?.milage}} mi</h6>
                                                              </li>
                                                              <li>
                                                                  <img src="assets/img/icons/Vector-3.png" />
                                                                  <h6>{{car?.fueltypes}}</h6>
                                                              </li>
                                                              <li>
                                                                  <img src="assets/img/icons/Vector-4.png" />
                                                                  <h6>{{car?.drivetypes}}</h6>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="product-footer">
                                                          <div class="product-button">
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>                    
                                      </div>
                                  </div> -->

                                <div class="product-item product-item__style-2 product-item__border-radius-card" style="margin: 20px;">
                                    <div class="product-image">
                                        <img [src]="car?.singleImages" />
                                        <div class="chip chip-red">
                                            <div>In Auction</div>
                                        </div>
                                        <div class="product-image-dark-bg">
                                            <div class="product-button">
                                                <a (click)="onClickDetails(car?._id)"
                                                    class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>details</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title">{{car?.make}} {{car?.model}} {{car?.year}}</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Stating Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">{{car?.startingBid}}</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value">{{car?.lotNo}}</span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value"><span>{{car?.vin.length>6 ? '**' : ''}}</span>{{car?.vin.length > 6 ? car?.vin.slice(-6) : car?.vin}}</span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="assets/img/icons/Vector-1.png" />
                                                <h6>{{car?.engines}} ltr</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-2.png" />
                                                <h6>{{car?.milage}} mi</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-3.png" />
                                                <h6>{{car?.fueltypes}}</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-4.png" />
                                                <h6>{{car?.drivetypes}}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- <div class="product-footer"> -->
                                        <!-- <div class="product-button">
                                            <a href="" class="secondary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Details</span></a>
                                            <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a>
                                        </div> -->

                                    <!-- </div> -->
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a routerLink="/vehicle-details" [queryParams]="{id:car?._id}" class="primary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Pre Bid</span></a>
                                            <!-- <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a> -->
                                        </div>
            
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                        <!-- <div class="col-lg-3 items" *ngFor="let car of inventoryList">
                                <div class="product-item product-item__style-2">
                                    <div class="product-image">
                                        <img src="assets/img/landing-page/image21.png" />
                                        <div class="chip chip-red">
                                           <div>Buy Now</div>
                                        </div>
                                        <div class="product-image-dark-bg">
                                            <div class="product-button">
                                                <a href="" class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>Details</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title">Audi Q5 2020</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Stating Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">3,40,000</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value">9087</span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value">8087</span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="assets/img/icons/Vector-1.png" />
                                                <h6>2.0 ltr</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-2.png" />
                                                <h6>1,65,000 mi</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-3.png" />
                                                <h6>Automatic</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-4.png" />
                                                <h6>Rear Wheel</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a href="" class="secondary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Details</span></a>
                                            <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a>
                                        </div>
            
                                    </div>
                                </div>
                            </div> -->
                        <!-- <div class="col-lg-3 items">
                                <div class="product-item product-item__style-2">
                                    <div class="product-image">
                                        <img src="assets/img/landing-page/image21.png" />
                                        <div class="chip chip-light-grey">
                                             <div>In Auction</div>
                                        </div>
                                        <div class="product-image-dark-bg">
                                            <div class="product-button">
                                                <a href="" class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>Details</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title">Audi Q5 2020</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Stating Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">3,40,000</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value">9087</span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value">8087</span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="assets/img/icons/Vector-1.png" />
                                                <h6>2.0 ltr</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-2.png" />
                                                <h6>1,65,000 mi</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-3.png" />
                                                <h6>Automatic</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-4.png" />
                                                <h6>Rear Wheel</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a href="" class="secondary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Details</span></a>
                                            <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a>
                                        </div>
            
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 items">
                                <div class="product-item product-item__style-2">
                                    <div class="product-image">
                                        <img src="assets/img/landing-page/image21.png" />
                                        <div class="chip chip-black">
                                             <div>Future</div>
                                        </div>
                                        <div class="product-image-dark-bg">
                                            <div class="product-button">
                                                <a href="" class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>Details</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title">Audi Q5 2020</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Stating Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">3,40,000</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value">9087</span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value">8087</span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="assets/img/icons/Vector-1.png" />
                                                <h6>2.0 ltr</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-2.png" />
                                                <h6>1,65,000 mi</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-3.png" />
                                                <h6>Automatic</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-4.png" />
                                                <h6>Rear Wheel</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a href="" class="secondary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Details</span></a>
                                            <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a>
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 items">
                                <div class="product-item product-item__style-2">
                                    <div class="product-image">
                                        <img src="assets/img/landing-page/image21.png" />
                                        <div class="chip chip-red">
                                             <div>Buy Now</div>
                                        </div>
                                        <div class="product-image-dark-bg">
                                            <div class="product-button">
                                                <a href="" class="secondary-btn secondary-btn__white secondary-btn_border-round secondary-btn__d-block "><span>Details</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title">Audi Q5 2020</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Stating Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">3,40,000</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value">9087</span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value">8087</span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="assets/img/icons/Vector-1.png" />
                                                <h6>2.0 ltr</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-2.png" />
                                                <h6>1,65,000 mi</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-3.png" />
                                                <h6>Automatic</h6>
                                            </li>
                                            <li>
                                                <img src="assets/img/icons/Vector-4.png" />
                                                <h6>Rear Wheel</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a href="" class="secondary-btn secondary-btn_border-round secondary-btn__d-block details-btn"><span>Details</span></a>
                                            <a href="" class="primary-btn primary-btn_border-round primary-btn__d-block"><span>Edit</span></a>
                                        </div>
            
                                    </div>
                                </div>
                            </div> -->


                        <!-- </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    <!-- <ng-template #callAPIDialog>
        <div class="modal-body">
                <img src="../../assets/images/warning.svg" style="height: 150px; margin: auto;" class="img-fluid mx-auto d-block">
                <button type="button" class="btn btn-danger">Close</button>
        </div>
    </ng-template> -->

    <ng-template #callAPIDialog>
        <div class="modal-body text-center">
            <div class="row">
                <div class="col-12">
                    <img src="../../assets/images/warning.svg" style="height: 150px;" class="img-fluid mx-auto d-block" alt="Warning Image">
                </div>
                <div class="col-12 mt-3">
                    <h2><b>Warning</b></h2>
                </div>
                <div class="col-12 mt-2">
                    <p>Please Login as a buyer.</p>
                </div>
                <div class="col-12 mt-3">
                    <button type="button" class="btn btn-primary" (click)="dialogRef.hide()">OK</button>
                </div>
            </div>
        </div>
    </ng-template>
    
