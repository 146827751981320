import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SallerBuyerService } from '../main/dashboard/sallerBuyer.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from '../shared/services1/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataServiceService } from '../shared/services/data-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmdialogServiceService } from '../shared/confirmation-dialog/confirmdialog-service.service';


@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  dialogRef: BsModalRef<any>;
  vechicleId: any;
  vehicleDetails: any;
  showVehicle: any;
  inventoryList: any = [];
  customBid:any;
  defaultImagCar: any;
  currentUser: any;
  bidHistory: any;
  auctionDetail: any;
  isAuctionVehicle: boolean = false;
  isNextCarAvailable: any;
  isPreviousCarAvailable: any;

  constructor(
    private router: ActivatedRoute,
    private service: SallerBuyerService,
    private defaultImag: SharedService,
    private toastr: ToastrService,
    private route: Router,
    private dialog: BsModalService,
    private dialogService: ConfirmdialogServiceService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.router.queryParams.subscribe((params: any) => {
      this.vechicleId = params['id'];
    });
    if (this.vechicleId) {
      this.getInventoryList(this.vechicleId);
    }
    this.defaultImagCar = this.defaultImag.getImage();
    this.getInventory();
  }
  customOptions: OwlOptions = {
    navText: [
      '<i class="fa fa-circle-chevron-left"></i>',
      '<i class="fa fa-circle-chevron-right"></i>',
    ],
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    // navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  getAuctionDetail() {
    this.service.auctionDetail({ auctionId: this.vehicleDetails?.auctionId }).subscribe((data: any) => {
      this.auctionDetail = data.result;
      console.log(this.auctionDetail);
    })
  }

  getColor(code) {
    return code;
  }

  getInventoryList(id) {
    this.vehicleDetails = undefined;
    this.showVehicle = undefined;
    this.service
      .getInventoryList({
        page: 1,
        pageLimit: 10000,
        inventoryId: id,
        pagination: true,
        apiType: 'web',
      })
      .subscribe((res: any) => {
        if (res.status == true) {
          this.vehicleDetails = res.result[0];
          let startingBid = this.vehicleDetails?.startingBid;
          this.getBidHIstory();

          // if(startingBid){
          //   startingBid = parseInt(startingBid)+500;
          // }else{
          //   startingBid = 500;
          // }
          
          // this.customBid = startingBid;


          this.isAuctionVehicle = this.vehicleDetails?.auctionId ? true : false;
          if (this.isAuctionVehicle)
            this.getAuctionDetail();
          this.isNextCarAvailable = this.vehicleDetails?.isNextCarAvailable
          this.isPreviousCarAvailable = this.vehicleDetails?.isPreviousCarAvailable
          if (this.vehicleDetails?.vinimages.length <= 0) {
            this.vehicleDetails?.vinimages.push({
              image: this.vehicleDetails?.vinimages[0],
            });
          }
          // if (this.currentUser) {
          //   this.getBidHIstory();
          // }
          this.showVehicle = this.vehicleDetails?.vinimages[0]?.image;
        }
      });
  }

  getInventory() {
    this.service
      .getInventoryList({ page: 1, pageLimit: 20, status: 1, apiType: 'web' })
      .subscribe((res: any) => {
        if (res.status == true) {
          this.inventoryList = res.result;
        }
      });
  }

  onClickImage(path: any) {
    this.showVehicle = path;
  }

  addToWatchList() {
    this.service.watchListAdd({ "inventoryId": this.vechicleId, "buyerId": this.currentUser?._id }).subscribe((res: any) => {
      if (res.status == true) {
        this.toastr.success("Successfully added");
      }
    })
  }

  decreaseVal(e:any){
    let bidAmount = parseInt((<HTMLInputElement>(document.getElementById('pre-bid-val'))).value);
    if(bidAmount>this.customBid){
      bidAmount = bidAmount-500;
      (<HTMLInputElement>(document.getElementById('pre-bid-val'))).value = ''+bidAmount;
    }
  }

  increaseVal(e:any){
    let bidAmount = parseInt((<HTMLInputElement>(document.getElementById('pre-bid-val'))).value);
    if(bidAmount){
      if(bidAmount == this.currentUser?.depositAmt || bidAmount > this.currentUser?.depositAmt){
        this.toastr.warning("you don't have enough credit ")
        return
      }else{
        bidAmount = bidAmount+500;
      }      
    }else{
      bidAmount = 500;
    }
    
    (<HTMLInputElement>(document.getElementById('pre-bid-val'))).value = ''+bidAmount;
  }

  preBid() {
    if (this.currentUser?.userType === 4 || this.currentUser?.userType === 5) {
      console.log(this.vehicleDetails)
      let bidAmount = (<HTMLInputElement>(
        document.getElementById('pre-bid-val')
      )).value;

      let reserve = parseInt(this.vehicleDetails?.reservePrice);
      if (!bidAmount) {
        return
      }
      // if (reserve >= parseInt(bidAmount)) {
      //   this.toastr.error("bid amount must be greater than reserve price");
      //   return
      // }
      let currAmount = bidAmount ? bidAmount : 0;
      let inventoryId = this.vehicleDetails._id;
      let userId = this.currentUser._id;
      let userName = this.currentUser.name;
      this.service.updatePreBid({ currAmount: currAmount, inventoryId: inventoryId, userId: userId, userName: userName, bidStatus: 'Open', bidType: 'Pre' }).subscribe((res: any) => {
        if (res.status == true) {
          this.vehicleDetails.reservePrice = parseInt(bidAmount);
          this.toastr.success(res.message);
          this.getBidHIstory();
          (<HTMLInputElement>(document.getElementById('pre-bid-val'))).value = '';
        } else {
          this.toastr.error(res.message);
        }
      });
    }else{
      this.openPopupAlert();
    }
  }
  showResult = false;
  name = 'Angular';

  imageZoom1(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    /*create lens:*/
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    /* create lens: */
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    /* insert lens: */
    img.parentElement.insertBefore(lens, img);
    /* calculate the ratio between image and lens: */
    cx = img.width / lens.offsetWidth;
    cy = img.height / lens.offsetHeight;
    /* set background properties for the result DIV: */
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = img.width * cx + "px " + img.height * cy + "px";
    /* execute a function when someone moves the cursor over the image, or the lens: */
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /* and also for touch screens: */
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    img.addEventListener("mouseenter", showResult);
    img.addEventListener("mouseleave", hideResult);
    lens.addEventListener("mouseenter", showResult);
    lens.addEventListener("mouseleave", hideResult);

    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }

    function showResult() {
      result.style.display = "block";
    }

    function hideResult() {
      result.style.display = "none";
    }
  }


  getBidHIstory() {
    this.bidHistory = undefined;
    let data = {
      bidStatus: "Open",
      bidType: "Pre",
      page: 1,
      pageLimit: 1000000,
      userId: this.currentUser?._id,
      vin: this.vehicleDetails?.vin
    }
    this.service.getPrebidWeb(data).subscribe((res: any) => {
      if (res.status == true) {
        this.bidHistory = res.result[0];
        let amt:any;
        if(this.bidHistory?.bidDetails?.latestBidAmount){
          if(this.bidHistory?.bidDetails?.latestBidAmount == this.currentUser?.depositAmt || this.bidHistory?.bidDetails?.latestBidAmount > this.currentUser?.depositAmt){
            this.toastr.warning("you don't have enough credit ")
            return
          }else{
            amt = this.bidHistory?.bidDetails?.latestBidAmount+500;
            this.customBid = amt
          }
        }else{
          this.customBid
        }        
      }
    })
  }

  onClickDetails(id: any) {
    this.route.navigate(['/vehicle-details'], { queryParams: { id: id } });
    this.router.queryParams.subscribe((params: any) => {
      this.vechicleId = params['id'];
    });
    if (this.vechicleId) {
      this.getInventoryList(this.vechicleId);
    }
  }

  transform(end: Date): string {
    let time = new Date(end);
    const timeDiff = time.getTime() - new Date().getTime();
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

    return formattedTime;
  }

  GetAuctionVehicleByDisplay(type) {
    let data = {
      auctionId: this.vehicleDetails?.auctionId,
      displayNo: this.vehicleDetails?.auctionDisplayNo,
    }
    type == 'n' ? data['next'] = true : data['previous'] = true;
    this.service.inventoryByAuction(data).subscribe((data: any) => {
      this.vehicleDetails = data.result[0];
      this.isNextCarAvailable = this.vehicleDetails?.isNextCarAvailable
      this.isPreviousCarAvailable = this.vehicleDetails?.isPreviousCarAvailable
      if (this.vehicleDetails?.vinimages.length <= 0) {
        this.vehicleDetails?.vinimages.push({
          image: this.vehicleDetails?.vinimages[0],
        });
      }
      if (this.currentUser) {
        this.getBidHIstory();
      }
      this.showVehicle = this.vehicleDetails?.vinimages[0]?.image;
    })
  }

  openPopupAlert() {
    let message = "Please login as a buyer."
    this.dialogService.showWarningDialog(message);
  }
}
