
<app-header class="hader-dark-bg-- header-fixed"></app-header>

<div class="my-account-home clearfix">
    <div class="sidebar-main-wpr" [@widthGrow]="state" style="height: '{getRightDivHeight()}';overflow: auto;">
        <div class="menu-overlay" *ngIf="menuOverlay"></div>
        <app-seller-sidebar></app-seller-sidebar>
     </div>
     <div class="main-dashboard-panel my-account-panel mobile-view">
        <div class="container-fluid">
            <div class="row">
                <div class="router-wpr col-md-12">
                    <div class="dashboard-menu" (click)="changeState()">
                        <span class="material-symbols-outlined">
                            segment
                        </span>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<div class="my-account-home clearfix">
    <div class="container">
<div class="row">
    <div class="col-lg-12">
        <div class="col-lg-3">
            <div class="side-navbar" style="width: 320px;">
                <ul class="nav nav-pills flex-column mb-auto">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" routerLink="/dashboard/myaccount">
                            <i class="fa-solid fa-house"></i>
                            Dashboard
                        </a>
                    </li>
                    <li>
                        <a class="nav-link" routerLink="/dashboard/vehicle-list">
                            <i class="fa-solid fa-car"></i>
                            My Vehicles
                        </a>
                    </li>
                    <li>
                        <a class="nav-link " routerLink="/dashboard/sold-vehicles">
                            <i class="fa-solid fa-tag"></i>
                            Sold Vehicles
                        </a>
                    </li>
                    <li>
                        <a class="nav-link active" routerLink="/dashboard/add-vehicle">
                            <i class="fa-solid fa-circle-plus"></i>
                            Add Vehicles
                        </a>
                    </li>
                </ul>
            </div>
        
        </div>
        
        <div class="col-lg-9">
            <router-outlet></router-outlet>
        
        </div>

    </div>

</div>
    </div>
</div> -->