<footer class="site-footer" *ngIf="hideFooter">
    <div class="overlay-photo-image-bg" data-bg-img="assets/img/landing-page/footer-bg-1.jpg" data-bg-opacity=".25" style="background-image: url(assets/img/landing-page/footer-bg-1.jpg   );opacity: 0.25;"></div>
    <div class="container">
        <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
            <div class="col-lg-6 col-md-6">
                <div class="footer-info">
                    <div class="footer-logo">
                        <a href="index.html" title="Shivaa">
                            <img src="assets/img/logo-white.png" alt="Logo">
                        </a>
                    </div>
                    <p>Al Qaryah is your go-to destination for buying and selling cars in the UAE and the Middle East. We offer a wide variety of vehicles, from compact cars to luxury SUVs and rugged trucks. You can easily sell your car online and get the best price without any hassle.
                        Our listings provide detailed information about each vehicle, including its make, model, color, mileage, and year of manufacture.</p>
                    <div>
                        <div class="mailchimp-form ">
                            <form class="one-field-form" method="post" action="#0">
                              <div class="field-group ">
                                <!-- <label class="email-label" for="email-input"> Subscribe to our news letter</label> -->
                                <input class="email-input " type="email" value="" name="EMAIL" id="email-input" placeholder="Enter Email Address" autocomplete="off">
                                <div class="cta-area">
                                  <input class="btn-solid subscribe-btn" type="submit" value="Subscribe Now" name="subscribe">
                                </div>
                              </div>
                            </form>
                          </div>
                    </div>
                    <div class="social-icon">
                        <a href="javascript:void(0);" title="Follow on Facebook"><i class="fa fa-facebook"
                                aria-hidden="true"></i></a>
                        <a href="javascript:void(0);" title="Follow on Instagram"><i class="fa fa-instagram"
                                aria-hidden="true"></i></a>
                        <a href="javascript:void(0);" title="Follow on Twitter"><i class="fa fa-twitter"
                                aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Auctions</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/">Get Started</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a  data-toggle="modal" data-target="#logInPopup" (click)="activateSignUpPopUp(false)">Login</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/contact-us">Contact</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Pricing</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/auction">Auction</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Explore</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Updates</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Testimonials</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Press</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Careers</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a href="#">Invectors</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Company</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/">Home</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/faq">Faq</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/about-us">About</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/terms-and-conditions">Terms & conditions</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>