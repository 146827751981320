import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class SharedService {
    url: any = environment.API_URL
    constructor(
        private http: HttpClient
    ) {

    }


    private hideFooter = new BehaviorSubject<any>({});
    hideFooter$ = this.hideFooter.asObservable();
  
    hideFooterElement(x: any) {
      this.hideFooter.next(x);
    }
 
    // src\app\shared\services1\shared.service.ts
    getImage(){
        return 'assets/img/landing-page/image21.png'
    }




    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    uploadVideo(file: any) {
        return this.http.post(this.url + "upload/video", file);
    }

    uploadPdf(file: any) {
        return this.http.post(this.url + "upload/pdf", file);
    }
    dayEndReport(data: any) {
        return this.http.post(this.url + "reciept/dayend-report", data);
    }
}