import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BidStockService {
  url: any = environment.API_URL;
  public data$: BehaviorSubject<string> = new BehaviorSubject('');
  public latestBidData$: BehaviorSubject<string> = new BehaviorSubject('');
  public messageData$: BehaviorSubject<string> = new BehaviorSubject('');
  public activeUser$: BehaviorSubject<string> = new BehaviorSubject('');
  public timerDuration$: BehaviorSubject<string> = new BehaviorSubject('');
  public onlineBidLast$: BehaviorSubject<string> = new BehaviorSubject('');
  public highLightFlag$: BehaviorSubject<string> = new BehaviorSubject('');
  public skipStatus$: BehaviorSubject<string> = new BehaviorSubject('');
  public inventryData$: BehaviorSubject<string> = new BehaviorSubject('');
  constructor(private http: HttpClient, private socket: Socket) {}

  joinAuction(auctionId: any, inventoryId: any) {
    this.socket.connect();
    // Send the auction ID to the server to join the corresponding room
    this.socket.emit('joinAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  }

  addBid(data: Object) {
    this.socket.connect();
    this.socket.emit('bidData', data);
  }

  public getShowHighLightFlag = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('highlightEvent', (data: any) => {
      this.highLightFlag$.next(data);
    });
    return this.highLightFlag$.asObservable();
  };

  public getUserCounts = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('userCount', (data: any) => {
      this.activeUser$.next(data);
    });
    return this.activeUser$.asObservable();
  };

  public getInventoryData = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('inventryData', (data: any) => {
      this.inventryData$.next(data);
    });
    return this.inventryData$.asObservable();
  };

  public getOnlineBidTime = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('onlineBidSartTime', (data: any) => {
      this.onlineBidLast$.next(data);
    });
    return this.onlineBidLast$.asObservable();
  };

  public getCurrentBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('data', (data: any) => {
      this.data$.next(data);
    });
    return this.data$.asObservable();
  };

  public callBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('getBidData', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public getMessage = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('bidMessage', (data: any) => {
      //if (data.auctionId == auctionId) {
      this.messageData$.next(data);
      // }
    });
    return this.messageData$.asObservable();
  };

  public closeBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('closeAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public puseBids = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('puaseAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public OnlineUnsold = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('OnlineUnsold', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public getBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('getData', (data: any) => {
      //console.log("============>",data)
      //if (data.auctionId == auctionId) {
      this.latestBidData$.next(data);
      // }
    });
    return this.latestBidData$.asObservable();
  };

  public getOnlimeTimerDuration = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('onlieTimerDuration', (data: any) => {
      //console.log("============>",data)
      //if (data.auctionId == auctionId) {
      this.timerDuration$.next(data);
      // }
    });
    return this.timerDuration$.asObservable();
  };

  // bidHistory() {
  //   return Observable.create((observer) => {
  //     this.socket.on('bidHistory', (message: any) => {
  //       // console.log('output message', message);
  //       observer.next(message);
  //     });
  //   });
  // }

  socketon() {
    this.socket.connect();
    console.log('this.socket', this.socket);
  }

  public getSkipStatus = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('skipStatus', (data: any) => {
      this.skipStatus$.next(data);
    });
    return this.skipStatus$.asObservable();
  };

  socketend() {
    this.socket.disconnect();
  }
}
