import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SingUpService } from '../../services/signup.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {

  buyerTab: boolean = true;
  sellerTab: boolean;
  SignUpPopupSection: boolean = false;
  OTPVerifyPopupSection: boolean = false;
  termAndCondition: boolean = false;
  LogInPopupSection: boolean = true;
  ResetPasswordPopupSection: boolean = false;
  ForgotPasswordPopupSection: boolean = false;
  submitted:boolean;
  userType:any=4;
  resetEmilOrPhone = '';
  userFoundByEmail: any;
  userNotFound: boolean = false;
  isVerifyOtpChangePassword: boolean;
  isEnableVerifyBtn: boolean = true;
  hide:boolean = true;
  hide1:boolean = true;
  hide2:boolean = true;


  constructor(
    private fb: FormBuilder,
    private service: SingUpService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates,CountryISO.Oman, CountryISO.Kazakhstan, CountryISO.Iraq];


	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[\w !@#$%&*]/;
  userForm = this.fb.group({
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', Validators.required),
    term: new FormControl('', Validators.required),
  })


  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(5)])
  })

  otpForm = new FormGroup({
    first: new FormControl('', Validators.required),
    second: new FormControl('',Validators.required),
    third: new FormControl('',Validators.required),
    forth: new FormControl('',Validators.required),
  })

  resetPasswordForm = new FormGroup({
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
  })

  ngOnInit(): void {
    this.signUpPopUpEventCheck();
  }

  signUpPopUpEventCheck(){
    this.service.signUpEvent.subscribe((data: any) => {
      if(data === true){
        this.SignUpPopupActive();
      }
      if(data === false){
        this.LogInPopupActive();
      }
    });
  }

  buyerTabActive() {
    this.buyerTab = true;
    this.sellerTab = false;
    this.userType = 4;
  }

  sellerTabActive() {
    this.buyerTab = false;
    this.sellerTab = true;
    this.userType = 2;
  }



  SignUpPopupActive() {
    this.termAndCondition = false;
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = true;
  }

  LogInPopupActive() {
    this.termAndCondition = false;
    this.LogInPopupSection = true;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }
  ResetPasswordPopupActive() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = true;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }
  ForgotPasswordPopupActive() {
    
    if(this.isVerifyOtpChangePassword == true){
      let otp = this.otpForm.value.first+this.otpForm.value.second+this.otpForm.value.third+this.otpForm.value.forth; 
      this.isOtpMatchedFun(otp);
    }else{
      let otp = this.otpForm.value.first+this.otpForm.value.second+this.otpForm.value.third+this.otpForm.value.forth; 
      this.service.verifyOtp({"phone":this.userForm.value.phone.number}).subscribe((res:any)=>{
        if(res.status == true){
          localStorage.setItem("phone",JSON.stringify({"phone":this.userForm.value.phone}));
          document.getElementById('btnClose').click();
          this.LogInPopupSection = false;
          this.ForgotPasswordPopupSection = true;
          this.ResetPasswordPopupSection = false;
          this.OTPVerifyPopupSection = false;
          this.SignUpPopupSection = false;
          this.router.navigateByUrl('/sign-up/complete-profile');
          this.toastr.success(res.result);
        }else{
          this.toastr.error(res.message);
        }
      })
    }
    
  }

  closePopUp(){
    this.userForm.reset();
    this.otpForm.reset();
    this.userFoundByEmail as {};
    this.termAndCondition = false;
    this.resetEmilOrPhone = '';
    this.resetPasswordForm.reset();
  }

  forgotPasswordResetActive(){
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = true;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }

  isFormValid(): boolean {
    if(this.userForm.valid == true){
      if (this.userForm.value.term == true){
        return true;
      }
      else{
        this.toastr.error("Please accept term and policies");
        return false;
      }
    }else{
      return this.userForm.valid;
    }
  }

  OTPVerifyPopupActive() {

      // this.registerUser();
      if (this.userForm.invalid || this.userForm.pristine) return;
      if(this.userForm.value.password != this.userForm.value.confirmPassword){
        this.toastr.error("password and confirm password are not matching")
        return
      }
      let user = {
        "forApproval": true,
        "businessType": "external",
        "phone": this.userForm.value.phone.number,
        "password": this.userForm.value.password,
        "userType": this.userType,
        "email":this.userForm.value.email,
        "countryCode":this.userForm.value.phone.dialCode,
        "countryIso" : Object.keys(this.CountryISO).find(country => this.CountryISO[country] === this.userForm.value.phone.countryCode.toLowerCase()),
      }
  
      console.log(this.userForm.value);
      
      this.service.registerUser(user).subscribe((res:any)=>{
        if(res.status == true){
          console.log(res);

          localStorage.setItem("userId",JSON.stringify(res.result));
          localStorage.setItem("user",JSON.stringify(user));
          localStorage.setItem("sallerOrBuyer",(res.result.userType == 4 || res.result.userType == 5? 'buyer': 'saller'));
          this.toastr.success('registered successfully');
          this.LogInPopupSection = false;
          this.ForgotPasswordPopupSection = false;
          this.ResetPasswordPopupSection = false;
          this.termAndCondition = true;
          this.SignUpPopupSection = false;
        }else{
          this.toastr.error(res.message);
          return
        }
      })

    
  }


  acceptTermCondition(){
    this.termAndCondition = false;
    this.OTPVerifyPopupSection = true;
  }

  get f(){
    return this.userForm.controls;
  }

  SignUpOTPVerifyPopupActive() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }

  resetPasswordOTP(){
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = true;
    this.SignUpPopupSection = false;
  }

  isOtpMatchedFun(data){
    if(data === this.userFoundByEmail.password){
      this.forgotPasswordResetActive();
    }
    else{
      this.toastr.error('Please enter correct OTP!!!', 'OTP not valid');
    }
  }

  isPasswordMatchFun(){
    if(this.resetPasswordForm.value.password !== this.resetPasswordForm.value.confirmPassword){
      
      if(this.resetPasswordForm.value.password.length === this.resetPasswordForm.value.confirmPassword.length){
        this.toastr.error('Password and confirm Password not matching', 'Not Match');
      }
      return true;
    }
    else{
      return false;
    }
  }

  UpdatePasswordFun(){
    let data = {
      email: this.userFoundByEmail.email,
      password: this.resetPasswordForm.value.password
    }
    this.service.UpdatePassword(data).subscribe((data:any) =>{
      if(data.status){
        this.toastr.success('Password updated Successfully!!!!', 'Successfully updated');
        this.LogInPopupActive();
      }else{
        this.toastr.error('Not updated !! Please try again.', 'Error');
      }
    })
  }

  GetUserByEmail(){
    this.isVerifyOtpChangePassword = true;
    this.userFoundByEmail as {};
    this.userNotFound = false;
    let data = {
      email: this.resetEmilOrPhone
    }
    this.service.GetUserByEmail(data).subscribe((data:any) =>{
    if(data.status === true){
      this.resetPasswordOTP();
      this.userFoundByEmail = data.result;
    }
    else{
      this.userNotFound = true;
      this.toastr.error('User not Found!!!', 'Not Found');
    }
    })
  }

  // registerUser(){

  //   if (this.userForm.invalid || this.userForm.pristine) return;
  //   if(this.userForm.value.password != this.userForm.value.confirmPassword){
  //     this.toastr.error("password and confirm password are not matching")
  //     return
  //   }
  //   let user = {
  //     "forApproval": true,
  //     "businessType": "external",
  //     "phone": this.userForm.value.email,
  //     "password": this.userForm.value.password,
  //     "userType": this.userType
  //   }

  //   console.log(this.userForm.value);
    
  //   this.service.registerUser(user).subscribe((res:any)=>{
  //     if(res.status == true){
  //       this.toastr.success('registered successfully');
  //     }else{
  //       this.toastr.error(res.message);
  //       return
  //     }
  //   })
  // }


  signIn() {
    if (this.loginForm.invalid) return;
    let user = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      loginType:'web'
    }
    console.log(user);
    this.service.login(user)
      .subscribe((res: any) => {
        if (res.status == true) {
          document.getElementById('btnClose').click();
          this.toastr.success(res.message);
          if(res?.result?.userType==2 || res?.result?.userType==3){
            res.result.role = 'seller'
            this.router.navigateByUrl('/dashboard/seller/dashboard');
          }else{
            res.result.role = 'buyer'
            this.router.navigateByUrl('/dashboard/buyer/dashboard');
          }
          
          localStorage.setItem('currentUser', JSON.stringify(res.result));
        } else {
          this.toastr.error(res.message);
        }
      },
        err => {
          this.toastr.error(err.error.err);
        }
      )
  }

  checkBackPress(e:any, index:any){
    let key = e.key;
    if(key === 'Backspace'){
      this.isEnableVerifyBtn = true;
        if(index ==2){
            if(this.otpForm.value.second){
              this.otpForm.value.second =''
            }else{
              document.getElementById('first').focus();
            }
        }

        if(index ==3){
          if(this.otpForm.value.third){
            this.otpForm.value.third =''
          }else{
            document.getElementById('second').focus();
          }
        }

        if(index ==4){
          if(this.otpForm.value.forth){
            this.otpForm.value.forth =''
          }else{
            document.getElementById('third').focus();
          }
        }
      }
  }

  moveToAnother(e:any, index:any){
      if(index==1){
        if(this.otpForm.value.first!=''){
        document.getElementById('second').focus();
        }
      }
      else if(index==2){
        if(this.otpForm.value.second!=''){
          document.getElementById('third').focus();
        }
      }
      if(index==3){
        if(this.otpForm.value.third!=''){
          document.getElementById('forth').focus();
        }
      }
      if(index == 4){
        this.isEnableVerifyBtn = false;
      }
  }


  onlyNumbers:boolean;
  checkNumers(e:any){
    let check = isNaN(this.loginForm.value.email);
    if(check==false){
      if(this.loginForm.value.email.length<=10){
        this.onlyNumbers = true;
      }else{
        this.onlyNumbers = false;
      }
    }else{
      this.onlyNumbers = false;
    }
  }
  

}
