<div class="side-navbar">
    <ul class="nav nav-pills flex-column mb-auto">
       
        
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/dashboard/buyer/dashboard">
                <span class="material-symbols-outlined"> home </span>
                <span class="label-menu"> Dashboard </span>
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/buyer/edit-profile">
                <span class="material-symbols-outlined"> manage_accounts </span>
                <span class="label-menu">Profile </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/my-bids">
                <span class="material-symbols-outlined"> gavel </span>
                <span class="label-menu"> My Bids </span>
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/buyer/won-vehicles">
                <span class="material-symbols-outlined"> trophy </span>
                <span class="label-menu"> Won Vehicles </span>
            </a>
        </li>
        <!-- <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/buy-now">
                <span class="material-symbols-outlined"> directions_car </span>
                <span class="label-menu"> Buy Now </span>
            </a>
        </li> -->
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/buyer/watchlist">
                <span class="material-symbols-outlined"> bookmark </span>
                <span class="label-menu"> Watchlist </span>
            </a>
        </li>
        <li>
            <a class="nav-link"  routerLinkActive="active" routerLink="/dashboard/buyer/order-history">
                <span class="material-symbols-outlined"> history </span>
                Deposit
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/buyer/transaction">
                <span class="material-symbols-outlined">
                    wallet
                    </span>
                <span class="label-menu"> Payment </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/history">
                <span class="material-symbols-outlined"> history </span>
                <span class="label-menu"> History & Gatepass </span>
            </a>
        </li>
        <li>
            <a class="nav-link "  routerLinkActive="active" routerLink="/dashboard/buyer/statement">
                <span class="material-symbols-outlined"> receipt_long </span>
                <span class="label-menu"> Statement </span>
            </a>
        </li>

    </ul>
</div>