import { Component, OnInit } from '@angular/core';
import { SingUpService } from '../services/signup.service';
import { SharedService } from '../services1/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private signUpService: SingUpService,
    private sharedService: SharedService
  ) { 
  }
  hideFooter:boolean = false;

  ngOnInit(): void {

    this.sharedService.hideFooter$.subscribe((result:any)=>{
      this.hideFooter = false;
      setTimeout(() => {
        this.hideFooter = true;
      },4000)
    })

    setTimeout(() => {
      this.hideFooter = true;
    },3000)
  }

  
  activateSignUpPopUp(isEnable){
    this.signUpService.EnableSignUpModel(isEnable)
  }
  

}
