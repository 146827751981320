<section class="testimonial">


    <div class="container">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
            <div class="tst-wrps" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="600">
                <div class="card">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
                        data-interval="100000">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-3 align-items-start">
                                                    <img src="https://bootstraplily.com/demo/testimonial-slider/2.jpg"
                                                        alt="" class="img-fluid">
                                                </div>
                                                <div class="col-sm-9">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p>"I attended a car auction and had a great experience. The staff
                                                        were
                                                        helpful,
                                                        bidding was efficient, and I found a car I
                                                        wanted at a good price. The transparency of the auction was
                                                        appreciated.
                                                        Highly recommend."</p>
                                                    <h3>— “John Doe - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-3 align-items-start">
                                                    <img src="https://bootstraplily.com/demo/testimonial-slider/2.jpg"
                                                        alt="" class="img-fluid">
                                                </div>
                                                <div class="col-sm-9">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p>"I attended a car auction and had a great experience. The staff
                                                        were
                                                        helpful,
                                                        bidding was efficient, and I found a car I
                                                        wanted at a good price. The transparency of the auction was
                                                        appreciated.
                                                        Highly recommend."</p>
                                                    <h3>— “John Doe - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-3 align-items-start">
                                                    <img src="https://bootstraplily.com/demo/testimonial-slider/2.jpg"
                                                        alt="" class="img-fluid">
                                                </div>
                                                <div class="col-sm-9">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p>"I attended a car auction and had a great experience. The staff
                                                        were
                                                        helpful,
                                                        bidding was efficient, and I found a car I
                                                        wanted at a good price. The transparency of the auction was
                                                        appreciated.
                                                        Highly recommend."</p>
                                                    <h3>— “John Doe - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="arrow-btn-section">
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"><i
                                        class="fa fa-chevron-left"></i></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"><i
                                        class="fa fa-chevron-right"></i></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial-matrix-left squre-matrix-8" data-aos="fade-right"
                data-aos-anchor-placement="top-center" data-aos-duration="1200">
                <img src="assets/img/icons/testimonial-matrix-left-1.svg" />
            </div>
            <div class="testimonial-matrix-left squre-matrix-9" data-aos="fade-left"
                data-aos-anchor-placement="top-center" data-aos-duration="1200">
                <img src="assets/img/icons/testimonial-matrix-right-1.svg" />
            </div>
        </div>

    </div>
</section>